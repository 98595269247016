import styled from "styled-components"
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { RegistrationForm } from "../pages/full-kit-registration/template";
import { mentalHealth } from "../constants/registration/mental-health";
import { habitsLifestyle } from "../constants/registration/habits-lifestyle";
import { personalDetailTwo } from "../constants/registration/personal-detail-two";
import { personalDetail } from "../constants/registration/personal-detail";
import { medicalHistory } from "../constants/registration/medical-history";
import useAuth from "../utils/useAuth";
import { clear } from "../redux/reducer/registration";
import api from "../utils/api";

const Fields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const PredictBioAgeWrapper = styled.div`
  padding: 0px 50px 50px;
  font-size: 20px;
`


export const PredictBioAge = () => {
  const [preditectBioAge, setPredictedBioAge] = useState()
  const dispatch = useDispatch();
  const values = useSelector((state) => state.registration);
  const { currentUser, claims } = useAuth()
  const fields = [
    ...Object.values(personalDetail).filter(field => !["biological_sex", "gender", "country", "ethnicity"].includes(field.id)),
    ...Object.values(personalDetailTwo).filter(field => !["overall_health"].includes(field.id)),
    ...Object.values(mentalHealth).filter(field => !["mental_health", "stress", "social_life"].includes(field.id)),
    ...Object.values(habitsLifestyle).filter(field => !["alcohol_consumption"].includes(field.id)),
    ...Object.values(medicalHistory).filter(field => !["medicine", "supplements"].includes(field.id))
  ]

  const calculateAge = (dateString) => {
      const birthDate = new Date(dateString);
      const today = new Date();

      let age = today.getFullYear() - birthDate.getFullYear();

      const isBirthdayPassed = 
          today.getMonth() > birthDate.getMonth() || 
          (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

      if (!isBirthdayPassed) {
          age -= 1;
      }

      return age;
  }

  const submit = async () => {
    try {
      const {
        height_inches,
        height_feet,
        weight,
        coffee_consumption,
        tobacco_consumption,
        has_diabetes,
        has_cancer,
        sleep_hours,
        physical_activity_days,
        trouble_sleeping,
        waking_up_condition,
        date_of_birth
      } = values
      const payload = {
        height_inches,
        height_feet,
        weight,
        coffee_consumption: coffee_consumption === 'Yes' ? 1 : 0,
        tobacco_consumption: tobacco_consumption === 'Yes' ? 1 : 0,
        has_diabetes: has_diabetes === 'Yes' ? 1 : 0,
        has_cancer: has_cancer === 'Yes' ? 1 : 0,
        sleep_hours: parseInt(sleep_hours.value),
        physical_activity_days: parseInt(physical_activity_days.value),
        trouble_sleeping: trouble_sleeping === 'Yes' ? 1 : 0,
        waking_up_condition: waking_up_condition === 'Yes' ? 1 : 0,
        age: calculateAge(date_of_birth)
      }
      const result = await api.post('/predict-biological-age', payload);
      setPredictedBioAge(result.data['predicted_bio_age'])
    } catch (error) {
      console.log(error)
      setPredictedBioAge('Error to calculate!')
    } finally {
      dispatch(clear())
    }
  }
  
  const requiredFields = fields.reduce((acc, field) => {
    if (field.subIds) return [...acc, ...field.subIds]
    return [...acc, field.id]
  }, [])
  
  useEffect(() => {
    dispatch(clear())
  }, [])

  if (currentUser && claims?.role !== 'SUPER_ADMIN') {
    return <></>
  }
  
  return <>
      <RegistrationForm
        subtitle='Mental Health'
        title='How have you been feeling lately?'
        Fields={Fields}
        fields={fields}
        progress={0}
        requiredFields={requiredFields}
        submitAction={submit}
        buttonCta="Submit"
      />
     { preditectBioAge && <PredictBioAgeWrapper>Predicted Bio Age: {preditectBioAge}</PredictBioAgeWrapper> }
  </>
}