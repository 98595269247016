import React from 'react';

export const House = ({ fill='#4F59F0' }) => {
    return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M6 19.5H9V14.5C9 14.2167 9.09583 13.9792 9.2875 13.7875C9.47917 13.5958 9.71667 13.5 10 13.5H14C14.2833 13.5 14.5208 13.5958 14.7125 13.7875C14.9042 13.9792 15 14.2167 15 14.5V19.5H18V10.5L12 6L6 10.5V19.5ZM4 19.5V10.5C4 10.1833 4.07083 9.88333 4.2125 9.6C4.35417 9.31667 4.55 9.08333 4.8 8.9L10.8 4.4C11.15 4.13333 11.55 4 12 4C12.45 4 12.85 4.13333 13.2 4.4L19.2 8.9C19.45 9.08333 19.6458 9.31667 19.7875 9.6C19.9292 9.88333 20 10.1833 20 10.5V19.5C20 20.05 19.8042 20.5208 19.4125 20.9125C19.0208 21.3042 18.55 21.5 18 21.5H14C13.7167 21.5 13.4792 21.4042 13.2875 21.2125C13.0958 21.0208 13 20.7833 13 20.5V15.5H11V20.5C11 20.7833 10.9042 21.0208 10.7125 21.2125C10.5208 21.4042 10.2833 21.5 10 21.5H6C5.45 21.5 4.97917 21.3042 4.5875 20.9125C4.19583 20.5208 4 20.05 4 19.5Z" fill={fill}/>
        </g>
    </svg>
    
}


