
export const SignoutIcon = ({ fill='#4F59F0', widht="24", height="25" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={widht} height={height} viewBox="0 0 24 25" fill="none">
      <g>
        <path d="M5 21.5C4.45 21.5 3.97917 21.3042 3.5875 20.9125C3.19583 20.5208 3 20.05 3 19.5V16.5C3 16.2167 3.09583 15.9792 3.2875 15.7875C3.47917 15.5958 3.71667 15.5 4 15.5C4.28333 15.5 4.52083 15.5958 4.7125 15.7875C4.90417 15.9792 5 16.2167 5 16.5V19.5H19V5.5H5V8.5C5 8.78333 4.90417 9.02083 4.7125 9.2125C4.52083 9.40417 4.28333 9.5 4 9.5C3.71667 9.5 3.47917 9.40417 3.2875 9.2125C3.09583 9.02083 3 8.78333 3 8.5V5.5C3 4.95 3.19583 4.47917 3.5875 4.0875C3.97917 3.69583 4.45 3.5 5 3.5H19C19.55 3.5 20.0208 3.69583 20.4125 4.0875C20.8042 4.47917 21 4.95 21 5.5V19.5C21 20.05 20.8042 20.5208 20.4125 20.9125C20.0208 21.3042 19.55 21.5 19 21.5H5ZM11.65 13.5H4C3.71667 13.5 3.47917 13.4042 3.2875 13.2125C3.09583 13.0208 3 12.7833 3 12.5C3 12.2167 3.09583 11.9792 3.2875 11.7875C3.47917 11.5958 3.71667 11.5 4 11.5H11.65L9.8 9.65C9.6 9.45 9.50417 9.21667 9.5125 8.95C9.52083 8.68333 9.61667 8.45 9.8 8.25C10 8.05 10.2375 7.94583 10.5125 7.9375C10.7875 7.92917 11.025 8.025 11.225 8.225L14.8 11.8C14.9 11.9 14.9708 12.0083 15.0125 12.125C15.0542 12.2417 15.075 12.3667 15.075 12.5C15.075 12.6333 15.0542 12.7583 15.0125 12.875C14.9708 12.9917 14.9 13.1 14.8 13.2L11.225 16.775C11.025 16.975 10.7875 17.0708 10.5125 17.0625C10.2375 17.0542 10 16.95 9.8 16.75C9.61667 16.55 9.52083 16.3167 9.5125 16.05C9.50417 15.7833 9.6 15.55 9.8 15.35L11.65 13.5Z" fill={fill}/>
      </g>
    </svg>
  )
}
