import { useEffect, useState } from "react"
import styled from 'styled-components'
import api from '../utils/api';
import Supplement from '../components/supplement'


const SupplementsPage = styled.div`
  padding: 1px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Title = styled.div`
    color: #051F73;
    font-family: 'Satoshi-Medium';
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 768px) {
        font-size: 24px;
    }
`;

const Subtitle = styled.div`
  color: #5B637D;
    font-family: 'Satoshi-Medium';
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 189.474% */
    letter-spacing: -0.247px;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`

const SupplementsList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 40px;
  flex-wrap: wrap;
  flex-grow: 1;
`

export default function Supplements() {
  const [supplements, setSupplements] = useState([])

  const hiddeFooter = () => {
    const footer = document.querySelector("footer");
    if (footer) {
      footer.style.display = "none";
    }
  }

  const fetchSupplements = async () => { 
    try {
      const response = await api.get('/supplements-list')
      if (response.data?.supplements) {
        setSupplements(response.data.supplements)
      }
    } catch (e) {

    }
  }
  useEffect(() => {
    hiddeFooter()
    fetchSupplements()
  }, [])

  function useHeightObserver(onHeightChange) {
    useEffect(() => {
      const observer = new ResizeObserver(() => {
        const height = document.documentElement.scrollHeight;
        onHeightChange(height);
        // eslint-disable-next-line no-restricted-globals
        parent.postMessage({ iframeHeight: height }, '*');
      });

      observer.observe(document.body);
  
      return () => observer.disconnect();
    }, [onHeightChange]);
  }

  useHeightObserver((newHeight) => {
    console.log("New page height:", newHeight);
  });

  return <SupplementsPage>
    <Title>Recommended just for you.</Title>
    <SupplementsList>
      {supplements.map(supplement => <Supplement supplement={supplement} width="266.5px" />)}
    </SupplementsList>
  </SupplementsPage>
}