import React from 'react';

export const Body= ({ fill='#4F59F0' }) => {
    return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
        <path d="M12 6.5C11.45 6.5 10.9792 6.30417 10.5875 5.9125C10.1958 5.52083 10 5.05 10 4.5C10 3.95 10.1958 3.47917 10.5875 3.0875C10.9792 2.69583 11.45 2.5 12 2.5C12.55 2.5 13.0208 2.69583 13.4125 3.0875C13.8042 3.47917 14 3.95 14 4.5C14 5.05 13.8042 5.52083 13.4125 5.9125C13.0208 6.30417 12.55 6.5 12 6.5ZM9 21.5V9.5H4C3.71667 9.5 3.47917 9.40417 3.2875 9.2125C3.09583 9.02083 3 8.78333 3 8.5C3 8.21667 3.09583 7.97917 3.2875 7.7875C3.47917 7.59583 3.71667 7.5 4 7.5H20C20.2833 7.5 20.5208 7.59583 20.7125 7.7875C20.9042 7.97917 21 8.21667 21 8.5C21 8.78333 20.9042 9.02083 20.7125 9.2125C20.5208 9.40417 20.2833 9.5 20 9.5H15V21.5C15 21.7833 14.9042 22.0208 14.7125 22.2125C14.5208 22.4042 14.2833 22.5 14 22.5C13.7167 22.5 13.4792 22.4042 13.2875 22.2125C13.0958 22.0208 13 21.7833 13 21.5V16.5H11V21.5C11 21.7833 10.9042 22.0208 10.7125 22.2125C10.5208 22.4042 10.2833 22.5 10 22.5C9.71667 22.5 9.47917 22.4042 9.2875 22.2125C9.09583 22.0208 9 21.7833 9 21.5Z" fill={fill}/>
    </g>
    </svg>
    
}
