/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components"
import { RegistrationForm } from "./template";
import { isInvalidEmail, isInvalidPw } from "../../utils/validator";
import api from "../../utils/api";
import { useLocation } from 'react-router-dom';
import { accountInformation } from "../../constants/registration/account-info";

const SubtitleWrapper = styled.div`
    margin-top: 15px;
`;

const Link = styled.a`
    font-weight: 800;
    color: gray;
    letter-spacing: 0.5px;
`;

const requiredFields = [
    'first_name',
    'last_name',
    'email',
    'password',
    'repeat_password',
    'phone'
]

const verifyEmail = async (email) => {
    try {
        const encodedEmail = encodeURIComponent(email);
        await api.get(`/email?value=${encodedEmail}`);
    } catch (err) {
        throw new Error('Email already registered')
    }
}

const validations = {
    email: async (value) => {
        isInvalidEmail(value)
        await verifyEmail(value)
    },
    password: (value) => {
        isInvalidPw(value)
    },
    other: (values) => {
        if (values.password !== values.repeat_password) throw new Error('Passwords must match')
    }
}

const Fields = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
        "first_name last_name"
        "email email"
        "phone phone"
        "password password"
        "repeat_password repeat_password";
    box-sizing: border-box;

    @media (max-width: 768px) {
        grid-template-areas: 
            "first_name first_name"
            "last_name last_name"
            "email email"
            "phone phone"
            "password password"
            "repeat_password repeat_password";
    }
`;

export const SignUpForm = () => {

    const search = new URLSearchParams(useLocation().search);
    const email = search.get('email');
    const first_name = search.get('first_name');
    const last_name = search.get('last_name');

    return <RegistrationForm01
        subtitle='Account Information'
        title='Create your account'
        progress={35}
        nextRoute='/sign-up/2'
        presetValue={{ email, first_name, last_name }}
    />

}

const Subtitle = () => {

    const location = useLocation();
    const query = location.search;
    const redirectUrl = `/registration/3${query}`;

    return <SubtitleWrapper>
        <Link href={`/sign-in?redirect=${redirectUrl}`}>Already have an account?</Link>
    </SubtitleWrapper>

}

export const RegistrationForm01 = ({
    title='Create your Account',
    progress=16,
    nextRoute='/registration/2',
    presetValue={}
}) => {

    return <RegistrationForm
        subtitle='Account Information'
        title={title}
        Fields={Fields}
        fields={Object.values(accountInformation)}
        progress={progress}
        nextRoute={nextRoute}
        requiredFields={requiredFields}
        validations={validations}
        presetValue={presetValue}
        subtitleHtml={<Subtitle />}
    >
        

    </RegistrationForm>
    
}

