import styled from 'styled-components';
import { Button, Column } from '../components/defaults';
import api from '../utils/api';
import { useState } from 'react';
import { NotificationManager } from 'react-notifications';

const Wrapper = styled(Column)`
    height: 100vh;
    align-items: center;
    padding: 40px;
    gap: 200px;
`;

export const UnsubscribeEmail = () => {

    const [unsubscribed, setUnsubscribed] = useState(false);
    const [loading, setLoading] = useState(false)

    const unsubscribe = async () => { 
        setLoading(true)
        await api.post('/email-unsubscribe');
        setLoading(false)
        setUnsubscribed(true);
        NotificationManager.success('Sucessfully unsubscribed from TruMe Mailing list!');
    }

    return <Wrapper>
        <h1>Unsubscribe from email list?</h1>
        <Button disabled={unsubscribed} loading={loading} onClick={unsubscribe}>{unsubscribed ? 'Sucessfully Unsubscribed!' : 'Click to Unsubscribe'}</Button>
    </Wrapper>


}