import { useState, useEffect } from 'react';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { clearIdentity, identify } from './heap';

const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [claims, setClaims] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Indicates that we're waiting for the auth state to be resolved
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      setCurrentUser(!!user);
      if (!!user) identify(user.email, user.uid);
      if (!user) clearIdentity();
      setIsLoading(false); // Auth state is resolved, update loading state
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (currentUser) {
      getAuth().currentUser?.getIdTokenResult().then(data => {
        if (data?.claims) {
          setClaims(data?.claims)
        }
      });
    }
  }, [currentUser])

  return { currentUser, isLoading, claims };
};

export default useAuth;