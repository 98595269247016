import styled from 'styled-components';


const SupplementProduct = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #E2E2FF;
    background: #FFF;
    width: ${props => props.width};
    height: 360px;
    @media (max-width: 768px) {
      width: 100%;
    }
`

const SupplementImage = styled.img`
    object-fit: contain;
    height: 248px;
    padding: 10px;
`

const SupplementFooter = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 32px;
    border-top: 1px solid #E2E2FF;
`

const SupplementName = styled.span`
    color: #000;
    font-family: 'Satoshi-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.208px;
`

const SupplementButton = styled.a`
    color: #4F59F0;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Satoshi-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    background: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
`


export default ({ supplement, width = '300px' }) => {

  return (
    <SupplementProduct width={width}>
        <SupplementImage src={supplement['image']} />
        <SupplementFooter>
            <SupplementName>{supplement['name']}</SupplementName>
            <SupplementButton href={supplement['url']} target='_blank'>View</SupplementButton>
        </SupplementFooter>
    </SupplementProduct>
  )
}